function Section_02(){
   return(
      <>
      <section class="p_l_d_b2">
      <div class="boarder_wrapper" data-aos="fade-up" data-aos-duration="800">
         <div class="news_title">NEWS</div>
         <div class="news_title_more"  onClick={() => window.open('https://yogico.kr/news', '_blank')}>
         더보기 &nbsp;<img src="http://yogibo.kr//web/img/icon/new/arrow_right.svg" alt=""></img>
         </div>
         
         <div class="row">

       
         <div class="board_list col-xl-4 col-md-4">
               <dl>
                  <dt class="board_img"><img src="https://yogibo.kr/yogico/img/banner/봄꽃.jpg"alt=""/></dt>
                  <dd>
                     <div class="board_title">
                        <dl>
                           <dt>요기보, 2025 영등포 여의도 봄꽃축제</dt>
                           <dd>후원 및 특별 프로그램 운영</dd>
                        </dl>		
                     </div>
                     <div class="board_cont">
                        글로벌 빈백 브랜드 요기보(Yogibo)가 4월 4일부터 8일까지 진행되는 
                        ‘2025 영등포 여의도 봄꽃축제’에 공식 후원사로 참여한다. 요기보는 
                        이번 축제에서 특별한 봄꽃 체험 공간과 공연 관람을 위한 편안한 휴식 
                        공간을 제공하며, 축제 방문객들에게 다채로운 체험 이벤트를 선사할 예정이다.

                        2025 영등포 여의도 봄꽃축제는 ‘모두의 정원(Garden for All)’이라는 테마 아래, 봄꽃정원, 
                        예술정원, 미식정원, 휴식정원 등 네 가지 테마 공간으로 꾸며진다. 요기보는 이러한 컨셉에 맞춰
                        ‘요기보 스프링 아일랜드’라는 포토존과 봄꽃스테이지 무대 앞 좌석을 요기보 빈백 소파로 꾸민다.  
                     </div>
                     <div class="board_more_button" 
                     onClick={()=>window.open('https://www.sisunnews.co.kr/news/articleView.html?idxno=222683  ','_blank')}>더 알아보기</div>
                  </dd>
               </dl>
               <p class="board_title_txt">Yogico X,News</p>
         </div>


         <div class="board_list col-xl-4 col-md-4">
               <dl>
                  <dt class="board_img"><img src="https://yogibo.kr/yogico/img/banner/뷰티트래블.jpg"alt=""/></dt>
                  <dd>
                     <div class="board_title">
                        <dl>
                           <dt>요기보, 2024 서울뷰티트래블위크</dt>
                           <dd>협찬사로 참여</dd>
                        </dl>		
                     </div>
                     <div class="board_cont">
                        글로벌 라이프스타일 브랜드 요기보(Yogibo)가 2024 서울뷰티트래블위크의 협찬사로 참여한다. 
                        요기보는 기업 명상 교육 전문 마음챙김 명상 플랫폼인 하루명상과의 협업을 통해, 요기보의 시그니처 
                        빈백 소파를 활용한 특별 웰니스 프로그램을 전 세계 최초로 선보일 예정이다.
                        서울시 주관으로 진행되는 2024 서울뷰티트래블위크는 올해로 3회째를 맞이하는 K-웰니스 행사로 서울숲 공원 내 
                        여러 공간에서 요가, 음악, 싱잉볼, 춤 등을 활용한 마음챙김 명상 프로그램을 통해 요기보 제품을 직접 체험할 수 있게 된다.
                     </div>
                     <div class="board_more_button" 
                     onClick={()=>window.open('http://www.kdpress.co.kr/news/articleView.html?idxno=132087 ','_blank')}>더 알아보기</div>
                  </dd>
               </dl>
               <p class="board_title_txt">Yogico X,News</p>
         </div>
         <div class="board_list col-xl-4 col-md-4">
               <dl>
                  <dt class="board_img"><img src="https://yogibo.kr/yogico/img/banner/뷰티트래블2.jpg"alt=""/></dt>
                  <dd>
                     <div class="board_title">
                        <dl>
                           <dt> Discover the Unique Beauty of Seoul at 2024 Beauty Travel Week</dt>
                        </dl>		
                     </div>
                     <div class="board_cont">               
                        - Event to take place across Seoul Forest Park and Seongdong-gu from Monday to Sunday, September 23 to 29.

                        - Boasts a wide variety of things to enjoy, taste, and do across Seongdong-gu, centered on the three topics of revival, refreshment, and rejuvenation.

                        - Over 50 local institutions and establishments to participate, including public parks, museums and galleries, eateries, and workshops.

                        - Seoul hopes to acquaint tourists from across Korea and around the world with “the unique beauty of Seoul.”
                     </div>
                     <div class="board_more_button" 
                     onClick={()=>window.open('https://www.businesswire.com/news/home/20240919559349/en/Discover-the-Unique-Beauty-of-Seoul-at-2024-Seoul-Beauty-Travel-Week ','_blank')}>더 알아보기</div>
                  </dd>
               </dl>
               <p class="board_title_txt">Yogico X,News</p>
         </div>

                
        
         </div>
      </div>
      </section>
        
      </>
   )
  }
  export default Section_02